// MealContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrandingStyle, OpeningTime, OrderMeal, Restaurant } from './types';
import { doc, getDoc } from '@firebase/firestore';
import { firestore, storage } from './firebase';
import { fromTimestamp } from './util';
import { FirebaseStorage, getDownloadURL, ref } from 'firebase/storage';

interface MealContextProps {
  selectedMeals: OrderMeal[];
  setSelectedMeals: React.Dispatch<React.SetStateAction<OrderMeal[]>>;
  restaurant: Restaurant | null;
  headerImage: string,
  fetchRestaurant: () => Promise<void>
  getBranding: () => BrandingStyle
}

const MealContext = createContext<MealContextProps | undefined>(undefined);

export const useMealContext = () => {
  const context = useContext(MealContext);
  if (!context) {
    throw new Error('useMealContext must be used within a MealProvider');
  }
  return context;
};

export const MealProvider: React.FC<{ children: React.ReactNode, restaurantId: string }> = ({ children, restaurantId }) => {
  const [selectedMeals, setSelectedMeals] = useState<OrderMeal[]>([]);
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [headerImage, setHeaderImage] = useState<string | null>(null)

  async function getHeaderImage() {

    try {

      const imageUrl = restaurant?.branding?.headerImage ?? 'default.png'

      return await getDownloadURL(ref(storage, imageUrl))
    } catch (e) {
      console.error("Could not load image", e);
      return null
    }
  }

  function getBranding() {

    if (restaurant?.branding?.style) {
      return restaurant?.branding?.style
    }

    return {
      primaryButtonColor: '#28a745',
      primaryButtonTextColor: '#fff',
      dangerButtonColor: '#dc3545',
      dangerButtonTextColor: '#fff',
      buttonColor: '#f9f9f9',
      buttonTextColor: '#333',
      backgroundColor: '#fff'
    } as BrandingStyle
  }

  const fetchRestaurant = async () => {
    try {

      const restaurantDocRef = doc(firestore, 'restaurants', restaurantId);
      const restaurantDocSnap = await getDoc(restaurantDocRef);


      if (restaurantDocSnap.exists()) {
        const data = restaurantDocSnap.data();

        const openingHours: OpeningTime[] = data.contact.openingHours.map((unmappedOpenTime: any) => {
          const open = unmappedOpenTime.open ? fromTimestamp(unmappedOpenTime.open) : undefined
          const close = unmappedOpenTime.close ? fromTimestamp(unmappedOpenTime.close) : undefined
          return { open, close, closed: unmappedOpenTime.closed }
        });

        setRestaurant({
          id: restaurantId, name: data.name, menu: data.menu, contact: { ...data.contact, openingHours }, branding: data.branding, admins: data.admins, employees: data.employees, ingredients: data.ingredients, productVariantGroups: data.productVariantGroups
        } as Restaurant);
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching restaurant:', error);
    }
  };

  useEffect(() => {
    fetchRestaurant();
  }, [restaurantId]);

  useEffect(() => {
    (async () => {
      setHeaderImage(await getHeaderImage())
    })()
  }, [restaurant]);

  if (!restaurant || headerImage === null) return <div>Loading...</div>;

  return (
    <MealContext.Provider value={{ selectedMeals, setSelectedMeals, restaurant, headerImage, fetchRestaurant, getBranding: getBranding }}>
      {children}
    </MealContext.Provider>
  );
};

