import { Timestamp } from "@firebase/firestore";
import dayjs, { Dayjs } from "dayjs";

export const swedishDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];

export function toTimestamp(date: Dayjs) {
    return new Timestamp(date.unix(), 0)
}
export function fromTimestamp(date: Timestamp) {
    return dayjs(date.toMillis())
}
export function getSwedishDayOfWeek() {

    if (dayjs().day() === 0) {
        return 6
    }
    return (dayjs().day() - 1)
}


