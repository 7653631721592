// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MealProvider } from './MealContext'; // Import the MealProvider
import ShowOrders from './ShowOrders';
import { WithAuthentication } from './WithAuthentication';
import Login from './Login';
import MenuManager from './MenuManager';
import TabView from './TabView';
import EditOpeningTimes from './EditOpeningTimes';
import ContactEditor from './ContactEditor';

const App: React.FC = () => {

  const restaurantId = window.location.hostname === 'localhost' ? window.location.hostname : window.location.hostname.replace(/^backoffice\./, '');

  const adminTabs = [
    { title: 'Meny', content: <MenuManager restaurantId={restaurantId} /> },
    { title: 'Öppettider', content: <EditOpeningTimes restaurantId={restaurantId} /> },
    { title: 'Kontakt', content: <ContactEditor /> },
  ];

  return (
    <Router>
      <MealProvider restaurantId={restaurantId}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<WithAuthentication requireAdmin={false}>
            <ShowOrders restaurantId={restaurantId} />
          </WithAuthentication>} />
          <Route path="/admin" element={<WithAuthentication requireAdmin={true}>
            <TabView tabs={adminTabs} />
          </WithAuthentication>} />
        </Routes>
      </MealProvider>
    </Router>
  );
};

export default App;
