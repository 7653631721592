import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB3dEauDtTLlNQ5WPnpXzSjQVI8po1AIt0",
  authDomain: "tasteexpress-production.firebaseapp.com",
  projectId: "tasteexpress-production",
  storageBucket: "tasteexpress-production.appspot.com",
  messagingSenderId: "130034155464",
  appId: "1:130034155464:web:38c6c77d8153844e640afd",
  measurementId: "G-7W8GSH1MZF"

};

const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const database = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp)
