import React, { useState, useEffect, ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import 'firebase/auth';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useMealContext } from './MealContext';

interface Props {
    children: typeof React.Children | ReactNode | ReactElement;
    requireAdmin: boolean
}

const useAuthentication = (requireAdmin: boolean = false) => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);
    const { restaurant } = useMealContext()
    useEffect(() => {
        const checkAuth = async () => {
            try {
                onAuthStateChanged(auth, (user) => {
                    if (requireAdmin) {
                        setAuthenticated(restaurant!.admins.find(userId => user!.uid === userId) != undefined);
                    }
                    else {
                        setAuthenticated(!!user);
                    }
                });
            } catch (error) {
                console.error("Authentication failed", error);
                setAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    return authenticated;
};

export const WithAuthentication: React.FC<Props> = ({ children, requireAdmin = false }) => {

    const authenticated = useAuthentication(requireAdmin)
    const navigate = useNavigate()

    if (authenticated === null) {
        return <div>Loading...</div>
    }

    if (!authenticated) {
        navigate(`/login`)
        return null;
    }

    return <>
        {children}
    </>;
}