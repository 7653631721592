
import React, { useState, useRef, useEffect } from 'react';
import { Ingredient, Meal } from './types';
import { Guid } from 'guid-typescript';
import { useMealContext } from './MealContext';
import { doc, updateDoc } from '@firebase/firestore';
import { firestore } from './firebase';



const SelectIngredientModal: React.FC<{ meal: Meal }> = ({ meal }) => {


    const { restaurant, fetchRestaurant } = useMealContext();
    const [ingredients, setIngredients] = useState<Ingredient[]>(JSON.parse(JSON.stringify(restaurant?.ingredients)));
    const [selectedIngredients, setSelectedIngredients] = useState<Ingredient[]>(JSON.parse(JSON.stringify(meal.ingredients)));
    const dialogRef = useRef<any>(null);

    const buttonStyle = {
        backgroundColor: '#f9f9f9',
        color: '#333',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        marginLeft: '5px',
        marginRight: '5px'
    };

    const primaryButtonStyle = {
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        marginLeft: '5px',
    };

    const inputStyle = {
        margin: '5px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: "150px"
    };

    const modalStyle = {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    };

    const modalContentStyle: React.CSSProperties = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    };
    const modalBodyStyle: React.CSSProperties = {
        maxHeight: '80vh',
        overflowY: 'auto'
    };

    const closeStyle = {
        top: '10px',
        right: '10px',
        cursor: 'pointer'
    };

    const listStyle = {
        listStyle: 'none',
        padding: 0
    };

    const listItemStyle = {
        marginBottom: '10px'
    };

    const handleOpenModal = async () => {
        console.log("opening modal");

        setIngredients(JSON.parse(JSON.stringify(restaurant?.ingredients)))
        document.body.style.overflow = 'hidden';
        dialogRef.current.showModal();
    };

    const handleCloseModal = () => {
        document.body.style.overflow = 'visible';
        dialogRef.current.close();
    };

    const handleSaveAndCloseModal = async () => {

        try {
            const restaurantRef = doc(firestore, "restaurants", restaurant!.id)
            const updatedMenu = restaurant?.menu.map(menu => {
                return {
                    ...menu,
                    meals: menu.meals.map(y => {
                        if (y.id === meal.id) {
                            return { ...meal, ingredients: selectedIngredients };
                        }
                        return y;
                    })
                };
            });

            await updateDoc(restaurantRef, { menu: updatedMenu });

            console.log('Ingredients updated successfully!');
        } catch (error) {
            console.error('Error updating ingredients:', error);
            throw error;
        }

        await fetchRestaurant()

        handleCloseModal()
    };

    const handleSelectIngredient = (ingredientToSelect: Ingredient) => {
        setSelectedIngredients([...selectedIngredients, ingredientToSelect]);
        setIngredients(ingredients.filter(ingredient => ingredient.id !== ingredientToSelect.id));
    };

    const handleRemoveIngredient = (ingredientToRemove: Ingredient) => {
        setSelectedIngredients(selectedIngredients.filter(ingredient => ingredient.id !== ingredientToRemove.id));
        setIngredients([...ingredients, ingredientToRemove]);
    };

    return (
        <>
            <button style={buttonStyle} onClick={handleOpenModal}>Välj ingredienser</button>
            <dialog ref={dialogRef} style={modalStyle}>
                <div style={{
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}>
                    <div style={modalContentStyle}>
                        <span style={closeStyle} onClick={handleCloseModal}>&times;</span>
                        <div style={modalBodyStyle}>
                            <h2>Välj Ingredienser</h2>

                            <div style={{ borderBottom: '2px solid #000', paddingBottom: '10px', marginTop: "20px" }} >
                                <ul style={listStyle}>
                                    {selectedIngredients.map((ingredient, index) => (
                                        <li key={index} style={listItemStyle}>
                                            {ingredient.name}
                                            &nbsp;
                                            {ingredient.price} kr
                                            <button style={buttonStyle} onClick={() => handleRemoveIngredient(ingredient)}>Ta bort</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <ul style={listStyle}>
                                {ingredients.map((ingredient, index) => (
                                    <li key={index} style={listItemStyle}>
                                        {ingredient.name}
                                        &nbsp;
                                        {ingredient.price} kr
                                        <button style={buttonStyle} onClick={() => handleSelectIngredient(ingredient)}>Välj</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <button style={primaryButtonStyle} onClick={handleSaveAndCloseModal}>Spara</button>
                        <button style={buttonStyle} onClick={handleCloseModal}>Avbryt</button>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default SelectIngredientModal;
