import React, { useState, useRef } from 'react';
import { ProductVariantGroup, ProductVariant } from './types';
import { Guid } from 'guid-typescript';
import { useMealContext } from './MealContext';
import { doc, updateDoc } from '@firebase/firestore';
import { firestore } from './firebase';

const ProductVariantModal = () => {
    const { restaurant, fetchRestaurant } = useMealContext();
    const [newProductVariantGroupName, setNewProductVariantGroupName] = useState<string>("");
    const [newProductVariantName, setNewProductVariantName] = useState<string>("");
    const [newProductVariantPrice, setNewProductVariantPrice] = useState<string>("0");
    const [productVariantGroups, setProductVariantGroups] = useState<ProductVariantGroup[]>(JSON.parse(JSON.stringify(restaurant?.productVariantGroups ?? [])));
    const dialogRef = useRef<any>(null);

    const buttonStyle = {
        backgroundColor: '#f9f9f9',
        color: '#333',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        marginLeft: '5px',
        marginRight: '5px'
    };

    const primaryButtonStyle = {
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        marginLeft: '5px',
    };

    const inputStyle = {
        margin: '5px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: "150px"
    };

    const modalStyle = {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    };

    const modalBodyStyle: React.CSSProperties = {
        maxHeight: '80vh',
        overflowY: 'auto'
    };


    const modalContentStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)'
    };

    const closeStyle = {
        top: '10px',
        right: '10px',
        cursor: 'pointer'
    };

    const listStyle = {
        listStyle: 'none',
        padding: 0
    };

    const listItemStyle = {
        marginBottom: '10px'
    };

    const handleOpenModal = async () => {

        document.body.style.overflow = 'hidden';
        dialogRef.current.showModal();
    };

    const handleCloseModal = () => {
        document.body.style.overflow = 'visible';
        dialogRef.current.close();
    };

    const handleSaveAndCloseModal = async () => {

        try {
            const restaurantRef = doc(firestore, "restaurants", restaurant!.id)

            await updateDoc(restaurantRef, {
                productVariantGroups: productVariantGroups
            });
            console.log('ProductVariantGroups updated successfully!');
        } catch (error) {
            console.error('Error updating productVariantGroups:', error);
            throw error;
        }

        await fetchRestaurant()

        console.log("fetchRestaurant");


        handleCloseModal()
    };

    const handleAddProductVariantGroup = () => {
        if (newProductVariantGroupName.trim() !== '') {
            setProductVariantGroups([...productVariantGroups, { id: Guid.create().toString(), name: newProductVariantGroupName, variants: [] }]);
            setNewProductVariantGroupName("");
        }
    };

    const handleRemoveProductVariantGroup = (groupId: string) => {
        const updatedGroups = productVariantGroups.filter(group => group.id !== groupId);
        setProductVariantGroups(updatedGroups);
    };

    const handleAddProductVariant = (groupId: string) => {
        if (newProductVariantName.trim() !== '') {
            const price = parseFloat(newProductVariantPrice);
            const updatedGroups = productVariantGroups.map(group => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        variants: [...group.variants, { id: Guid.create().toString(), name: newProductVariantName, price }]
                    };
                }
                return group;
            });
            setProductVariantGroups(updatedGroups);
            setNewProductVariantName("");
            setNewProductVariantPrice("0");
        }
    };

    const handleRemoveProductVariant = (groupId: string, variantId: string) => {
        const updatedGroups = productVariantGroups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    variants: group.variants.filter(variant => variant.id !== variantId)
                };
            }
            return group;
        });
        setProductVariantGroups(updatedGroups);
    };

    return (
        <>
            <button style={buttonStyle} onClick={handleOpenModal}>Hantera produktvarianter</button>
            <dialog ref={dialogRef} style={modalStyle}>
                <div style={{
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}>
                    <div style={modalContentStyle}>
                        <span style={closeStyle} onClick={handleCloseModal}>&times;</span>
                        <div style={modalBodyStyle}>
                            <h2>Produkt Varianter</h2>
                            <div>
                                <input
                                    style={inputStyle}
                                    type="text"
                                    value={newProductVariantGroupName}
                                    onChange={(e) => setNewProductVariantGroupName(e.target.value)}
                                    placeholder="Namn"
                                />
                                <button style={buttonStyle} onClick={handleAddProductVariantGroup}>Lägg till grupp</button>
                            </div>
                            {productVariantGroups.map(group => (
                                <div key={group.id}>
                                    <h3>{group.name}<button style={buttonStyle} onClick={() => handleRemoveProductVariantGroup(group.id)}>Ta bort grupp</button></h3>

                                    <div>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={newProductVariantName}
                                            onChange={(e) => setNewProductVariantName(e.target.value)}
                                            placeholder="Namn"
                                        />
                                        <input
                                            style={inputStyle}
                                            type="number"
                                            value={newProductVariantPrice}
                                            onChange={(e) => setNewProductVariantPrice(e.target.value)}
                                            placeholder="Pris"
                                        />
                                        <button style={buttonStyle} onClick={() => handleAddProductVariant(group.id)}>Lägg till variant</button>
                                    </div>
                                    <ul style={listStyle}>
                                        {group.variants.map(variant => (
                                            <li key={variant.id} style={listItemStyle}>
                                                {variant.name} {variant.price} Kr
                                                <button style={buttonStyle} onClick={() => handleRemoveProductVariant(group.id, variant.id)}>Ta bort</button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <button style={primaryButtonStyle} onClick={handleSaveAndCloseModal}>Spara</button>
                        <button style={buttonStyle} onClick={handleCloseModal}>Avbryt</button>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default ProductVariantModal;
