import React, { useState } from 'react';
import { Meal, Menu } from './types';
import { useMealContext } from './MealContext';
import { Guid } from 'guid-typescript';
import { doc, updateDoc } from '@firebase/firestore';
import { firestore } from './firebase';
import IngredientModal from './IngredientModal';
import SelectIngredientModal from './SelectIngredientModal';
import ProductVariantModal from './ProductVariantModal';
import SelectProductVariantModal from './SelectProductVariantModal';

type EditingMeal = Meal & {
    menuId: string;
};

const MenuManager: React.FC<{ restaurantId: string }> = ({ restaurantId }) => {

    const { restaurant, fetchRestaurant } = useMealContext();

    const [newMenuName, setNewMenuName] = useState('');

    const [editingMeal, setEditingMeal] = useState<EditingMeal | null>(null);
    const [editingMenu, setEditingMenu] = useState<{ id: string; name: string } | null>(null);

    const addMeal = () => {
        const newMeal: EditingMeal = { id: Guid.create().toString(), name: "", description: "", ingredients: [], productVariants: [], menuId: restaurant!.menu[0].id, price: 0 };
        setEditingMeal(newMeal);
    };

    function editMeal(meal: Meal, menuId: string) {
        setEditingMeal({ ...meal, menuId });
    }

    async function saveMeal() {
        if (editingMeal) {
            const menus = restaurant!.menu;

            let mealExists = false;

            let editedMenus = menus.map(menu => {
                if (menu.id === editingMeal.menuId && menu.meals.some(x => x.id === editingMeal.id)) {
                    return {
                        ...menu,
                        meals: menu.meals.map(meal => {
                            if (meal.id === editingMeal.id) {
                                return editingMeal
                            }
                            mealExists = true
                            return meal
                        }),
                    }
                }
                return menu
            })

            if (!mealExists) {
                if (editedMenus.length > 0) {

                    const editedMenu = editedMenus.find(menu => menu.id === editingMeal.menuId)

                    if (editedMenu) {

                        editedMenus = menus.map(menu => {

                            if (menu.id === editedMenu.id) {
                                return {
                                    ...editedMenu,
                                    meals: [...editedMenu.meals, { ...editingMeal }],
                                }
                            }
                            return menu
                        });
                    } else {
                        console.log("Could not find the menu to edit");

                    }
                } else {
                    console.log("No menus found. Consider creating a new menu for this meal.");
                }
            }

            await saveMenus(editedMenus)
            setEditingMeal(null);
            await fetchRestaurant()

        }
    };

    function deleteMeal(mealToDelete: Meal) {
        const menus = restaurant!.menu;

        const editedMenus = menus.map(menu => {
            if (menu.meals.some(x => x.id === mealToDelete.id)) {
                return {
                    ...menu,
                    meals: menu.meals.filter(meal => meal.name !== mealToDelete.name),
                }
            }
            return menu
        })

        saveMenus(editedMenus);
    };

    async function addMenu() {
        if (newMenuName.trim() === '') {
            alert('Menu name cannot be empty.');
            return;
        }

        const newMenu = {
            id: Guid.create().toString(), // Assuming each menu needs a unique ID
            name: newMenuName,
            meals: [] // New menus start with no meals
        };

        const updatedMenus = [...restaurant!.menu, newMenu];
        await saveMenus(updatedMenus);
        setNewMenuName(''); // Reset input after adding
        await fetchRestaurant(); // Refresh data
    }

    async function deleteMenu(menuId: string) {
        if (!window.confirm("Are you sure you want to delete this menu? This will also remove all meals in it.")) {
            return;
        }

        const updatedMenus = restaurant!.menu.filter(menu => menu.id !== menuId);
        await saveMenus(updatedMenus);
        await fetchRestaurant();
    }



    async function saveMenus(menus: Menu[]) {

        try {
            const restaurantRef = doc(firestore, "restaurants", restaurantId)

            await updateDoc(restaurantRef, {
                menu: menus
            });
            console.log('Menu updated successfully!');
        } catch (error) {
            console.error('Error updating menu:', error);
            throw error;
        }
    }

    async function saveMenuName() {
        if (editingMenu) {
            const updatedMenus = restaurant!.menu.map((menu) => {
                if (menu.id === editingMenu.id) {
                    return { ...menu, name: editingMenu.name };
                }
                return menu;
            });

            await saveMenus(updatedMenus);
            setEditingMenu(null);
            await fetchRestaurant();
        }
    }

    async function moveMealUp(mealId: string, menuId: string) {
        const menus = restaurant!.menu.map((menu) => {
            if (menu.id === menuId) {
                const mealIndex = menu.meals.findIndex((meal) => meal.id === mealId);
                if (mealIndex > 0) { // Check to ensure the meal is not the first one
                    const mealToMoveUp = menu.meals[mealIndex];
                    menu.meals[mealIndex] = menu.meals[mealIndex - 1];
                    menu.meals[mealIndex - 1] = mealToMoveUp;
                }
            }
            return menu;
        });
        await saveMenus(menus);
        await fetchRestaurant()
    };

    async function moveMealDown(mealId: string, menuId: string) {
        const menus = restaurant!.menu.map((menu) => {
            if (menu.id === menuId) {
                const mealIndex = menu.meals.findIndex((meal) => meal.id === mealId);
                if (mealIndex < menu.meals.length - 1) { // Check to ensure the meal is not the last one
                    const mealToMoveDown = menu.meals[mealIndex];
                    menu.meals[mealIndex] = menu.meals[mealIndex + 1];
                    menu.meals[mealIndex + 1] = mealToMoveDown;
                }
            }
            return menu;
        });
        await saveMenus(menus);
        await fetchRestaurant()
    };

    const editButtonStyle = {
        backgroundColor: '#f9f9f9',
        color: '#333',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        marginLeft: '5px',
        marginRight: '5px'
    };

    const inputStyle = {
        margin: '5px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: "150px"
    };

    const menuItemStyle = {
        marginBottom: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px',
    };

    const buttonStyle = {
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        marginLeft: '5px',
    };

    const deleteButtonStyle = {
        backgroundColor: '#dc3545',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        marginLeft: '5px',
        marginRight: '5px'
    };

    if (restaurant === null) {
        return <div>Laddar...</div>
    }


    return (
        <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', marginBottom: '150px', backgroundColor: '#fff' }}>
            <h1>{restaurant!.name}</h1>
            <button style={buttonStyle} onClick={addMeal}>Lägg till rätt</button>
            <IngredientModal />
            <ProductVariantModal />
            {editingMeal && (
                <div>
                    <input
                        style={inputStyle}
                        type="text"
                        value={editingMeal.name}
                        onChange={e => setEditingMeal({ ...editingMeal, name: e.target.value })}
                        placeholder="Namn"
                    />
                    <input
                        style={inputStyle}
                        type="text"
                        value={editingMeal.description}
                        onChange={e => setEditingMeal({ ...editingMeal, description: e.target.value })}
                        placeholder="Beskrivning"
                    />
                    <input
                        style={inputStyle}
                        type="number"
                        value={editingMeal.price}
                        onChange={e => setEditingMeal({
                            ...editingMeal, price: Number(e.target.value)
                        })}
                        placeholder="Pris"
                    />
                    <select
                        value={editingMeal.menuId}
                        onChange={(e) => setEditingMeal({ ...editingMeal, menuId: e.target.value })}
                        style={inputStyle}
                    >
                        {restaurant.menu.map((menu) => (
                            <option key={menu.id} value={menu.id}>{menu.name}</option>
                        ))}
                    </select>

                    <button style={buttonStyle} onClick={saveMeal}>Spara rätt</button>
                    <button style={deleteButtonStyle} onClick={() => setEditingMeal(null)}>Avbryt</button>
                </div>
            )}
            <div style={{ borderTop: '2px solid #000', borderBottom: '2px solid #000', paddingBottom: '10px', fontSize: '24px', marginTop: "20px" }}>
                <input
                    style={{ ...inputStyle, marginTop: "20px" }}
                    type="text"
                    value={newMenuName}
                    onChange={(e) => setNewMenuName(e.target.value)}
                    placeholder="Namn på meny"
                />
                <button style={buttonStyle} onClick={addMenu}>Lägg till meny</button>
            </div>

            {
                restaurant.menu.map((menu, index) => (
                    <div key={index} style={{ marginBottom: '40px' }}>
                        {editingMenu && editingMenu.id === menu.id ? (
                            <>
                                <input
                                    type="text"
                                    value={editingMenu.name}
                                    onChange={(e) => setEditingMenu({ ...editingMenu, name: e.target.value })}
                                    style={inputStyle}
                                />
                                <button style={buttonStyle} onClick={saveMenuName}>Byt namn på meny</button>
                                <button style={deleteButtonStyle} onClick={() => setEditingMenu(null)}>Avbryt</button>
                            </>
                        ) : (
                            <h2 style={{ borderBottom: '2px solid #000', paddingBottom: '10px', fontSize: '24px' }}>
                                {menu.name}
                                <button style={editButtonStyle} onClick={() => setEditingMenu({ id: menu.id, name: menu.name })}>
                                    Byt namn på meny
                                </button>
                                <button style={deleteButtonStyle} onClick={() => deleteMenu(menu.id)}>
                                    Ta bort meny
                                </button>
                            </h2>
                        )}
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {menu.meals.map((meal, index) => (
                                <li key={index} style={menuItemStyle}>
                                    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>{meal.name}</h3>
                                    <p style={{ marginBottom: '5px', fontSize: '18px' }}>Pris: {meal.price} kr</p>
                                    <p style={{ fontSize: '16px', color: '#555' }}>Beskrivning: {meal.description}</p>
                                    <p style={{ fontSize: '16px', color: '#555' }}>Ingredienser: {meal.ingredients.map(x => x.name).join(", ")}</p>
                                    <button style={editButtonStyle} onClick={() => editMeal(meal, menu.id)}>Ändra</button>
                                    <button style={deleteButtonStyle} onClick={() => deleteMeal(meal)}>Ta bort</button>
                                    <button style={editButtonStyle} onClick={() => moveMealUp(meal.id, menu.id)}>Flytta upp</button>
                                    <button style={editButtonStyle} onClick={() => moveMealDown(meal.id, menu.id)}>Flytta ner</button>
                                    <SelectIngredientModal meal={meal} />
                                    <SelectProductVariantModal meal={meal} />
                                </li>
                            ))}
                        </ul>
                    </div>
                ))
            }
        </div >)
}

export default MenuManager
