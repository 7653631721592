import React, { useState } from 'react';

interface Tab {
    title: string;
    content: React.ReactNode;
}

interface TabViewProps {
    tabs: Tab[];
}

const TabView: React.FC<TabViewProps> = ({ tabs }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleTabClick = (index: number) => {
        setActiveTabIndex(index);
    };

    const tabButtonStyle = {
        backgroundColor: '#f1f1f1',
        border: '1px solid #ccc',
        borderBottom: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const activeTabButtonStyle = {
        ...tabButtonStyle,
        backgroundColor: '#fff',
        borderBottom: '1px solid #fff',
    };

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}>
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        style={index === activeTabIndex ? activeTabButtonStyle : tabButtonStyle}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.title}
                    </button>
                ))}
            </div>
            <div style={{ padding: '20px' }}>
                {tabs[activeTabIndex].content}
            </div>
        </div>
    );
};

export default TabView;
