import React, { useState } from 'react';
import { OpeningTime } from './types';
import dayjs, { Dayjs } from "dayjs";
import { useMealContext } from './MealContext';
import { doc, updateDoc } from '@firebase/firestore';
import { firestore } from './firebase';
import { swedishDays, toTimestamp } from './util';

const EditOpeningTimes: React.FC<{ restaurantId: string }> = ({ restaurantId }) => {

    const { restaurant } = useMealContext();

    const [openingHours, setOpeningHours] = useState<OpeningTime[]>(restaurant!.contact.openingHours);
    const [isEditing, setIsEditing] = useState(false);
    const [editedOpeningHours, setEditedOpeningHours] = useState<OpeningTime[]>([]);

    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            padding: '20px'
        },
        header: {
            marginBottom: '20px',
            fontSize: '1.5rem',
            color: '#333'
        },
        dayContainer: {
            marginBottom: '10px'
        },
        dayLabel: {
            fontWeight: 'bold',
            marginRight: '10px'
        },
        timeInputContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        timeInput: {
            marginRight: '5px',
            width: '100px',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ccc'
        },
        timeSeparator: {
            margin: '0 5px'
        },
        saveButton: {
            margin: '10px',
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        cancelButton: {
            margin: '10px',
            padding: '10px 20px',
            backgroundColor: '#dc3545',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        editButton: {
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        closedCheckbox: {
            marginLeft: '5px',
        },
        dayLabelContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '5px',
        },
        statusContainer: {
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
        },
    };

    const handleStartEditing = () => {
        setIsEditing(true);

        setEditedOpeningHours([...openingHours]);
    };

    const handleCancelEditing = () => {
        setEditedOpeningHours([...openingHours])
        setIsEditing(false);
    };

    const handleSaveChanges = async () => {
        setOpeningHours([...editedOpeningHours]);

        try {
            const restaurantRef = doc(firestore, "restaurants", restaurantId)

            await updateDoc(restaurantRef, {
                contact: {
                    ...restaurant!.contact, openingHours: editedOpeningHours.map(openingHours => {
                        return {
                            open: toTimestamp(openingHours.open),
                            close: toTimestamp(openingHours.close),
                            closed: openingHours.closed
                        }
                    })
                }
            });
            console.log('Opening hours updated successfully!');
        } catch (error) {
            console.error('Error updating Opening hours:', error);
            throw error;
        }

        setIsEditing(false);
    };

    const handleOpeningTimeChange = (index: number, field: 'open' | 'close', value: string) => {
        const updatedOpeningHours = [...editedOpeningHours];
        console.log("value", value);

        const hours = Number(value.split(":")[0])
        const minutes = Number(value.split(":")[1])

        updatedOpeningHours[index][field] = dayjs().startOf('day').set('hours', hours).set('minutes', minutes); // Convert string to Dayjs object
        setEditedOpeningHours(updatedOpeningHours);
    };

    const handleClosedChange = (index: number, closed: boolean) => {
        const updatedOpeningHours = [...editedOpeningHours];
        updatedOpeningHours[index].closed = closed;
        setEditedOpeningHours(updatedOpeningHours);
    };

    const renderDayOpeningTimeInputs = () => {

        return editedOpeningHours.map((openingTime, index) => (
            <div key={index} style={styles.dayContainer}>
                <div style={styles.dayLabelContainer}>
                    <label style={styles.dayLabel}>{swedishDays[index]}</label>
                    <input
                        type="checkbox"
                        checked={openingTime.closed}
                        onChange={(e) => handleClosedChange(index, e.target.checked)}
                        style={styles.closedCheckbox}
                    /> Stängd
                </div>
                {!openingTime.closed && (
                    <div style={styles.timeInputContainer}>
                        <input
                            type="time"
                            step="60"
                            value={openingTime.open.format('HH:mm')}
                            onChange={(e) => handleOpeningTimeChange(index, 'open', e.target.value)}
                            style={styles.timeInput}
                        />
                        <span style={styles.timeSeparator}>-</span>
                        <input
                            type="time"
                            step="60"
                            value={openingTime.close.format('HH:mm')}
                            onChange={(e) => handleOpeningTimeChange(index, 'close', e.target.value)}
                            style={styles.timeInput}
                        />
                    </div>
                )}
            </div>
        ));
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Redigera Öppettider</h2>
            {isEditing ? (
                <div>
                    {renderDayOpeningTimeInputs()}
                    <button style={styles.saveButton} onClick={handleSaveChanges}>Spara</button>
                    <button style={styles.cancelButton} onClick={handleCancelEditing}>Avbryt</button>
                </div>
            ) : (
                <>
                    {openingHours.map((openingTime, index) => (
                        <div key={index} style={styles.statusContainer}>
                            <label style={styles.dayLabel}>{swedishDays[index]}:</label>
                            <span>{openingTime.closed ? "Stängd" : "Öppen"}</span>
                            <span style={{ marginLeft: "5px" }}>{!openingTime.closed && `${openingTime.open.format("HH:mm")}-${openingTime.close.format("HH:mm")}`}</span>
                        </div>
                    ))}
                    <button style={styles.editButton} onClick={handleStartEditing}>Redigera Öppettider</button>
                </>
            )}
        </div>
    );
};

export default EditOpeningTimes;
