import React, { useState, useEffect, useRef } from 'react';
import { Timestamp, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { firestore, database, auth } from './firebase'; // Assuming you have initialized Firestore as 'db' in './firebase'
import { OrderData, OrderStatus } from './types';
import dayjs from 'dayjs';
import { onValue, ref, set } from 'firebase/database';
import { Guid } from 'guid-typescript';
import { fromTimestamp, toTimestamp } from './util';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

interface Props {
    restaurantId: string;
}

const ShowOrders: React.FC<Props> = ({ restaurantId }) => {

    const audioRef = useRef<HTMLAudioElement>(null);
    const navigate = useNavigate()

    const [orders, setOrders] = useState<OrderData[]>([]);
    const [filter, setFilter] = useState('active');
    const [timeToFinish, setTimeToFinish] = useState<{ [orderId: string]: number }>({});


    const fetchOrders = async () => {
        try {
            const orderDocRef = doc(firestore, 'orders', restaurantId);
            const orderDoc = await getDoc(orderDocRef);
            const data = orderDoc.data();

            const today = dayjs().format('YYYY-MM-DD');

            const todayOrders = data ? (data[today] as any[]) : [];

            const mappedTodayOrders = todayOrders.map(order => {
                return { ...order, createdAt: fromTimestamp(order.createdAt), readyAt: order.readyAt ? fromTimestamp(order.readyAt) : undefined } as OrderData
            })

            const sortedOrders = mappedTodayOrders.sort((a, b) => b.orderNumber - a.orderNumber);

            setOrders(sortedOrders ?? []);
        } catch (error: any) {
            console.error('Error fetching orders: ', error);
            console.error(JSON.stringify(error));
            if (error.code === "permission-denied") {
                navigate(`/login`)
            }
        }
    };
    useEffect(() => {
        fetchOrders();
        onValue(ref(database, `orders/${restaurantId.replace(/\./g, '')}/created`), (snapshot) => {
            fetchOrders();
            if (audioRef.current) {
                audioRef.current.play().catch((error) => {
                    // Handle error, such as the user not interacting with the document first
                    console.error('Error playing audio:', error);
                });
            }
        });
    }, []);

    function getOrderStatusBackgroundColor(orderStatus: OrderStatus) {
        switch (orderStatus) {
            case 'created':
                return '#f9f9f9'
            case 'accepted':
                return '#cfe2f3'
            case 'finished':
                return '#d4edda'
            case 'delivered':
                return '#20c997'
            case 'declined':
                return '#f8d7da'
            default:
                return '#f9f9f9'
        }
    }

    async function handleLogout() {

        try {
            await signOut(auth)
            console.log('User signed out successfully');

        }
        catch (error) {
            console.error('Error signing out:', error);
        }
    }

    async function updateStatus(orderId: string, orderStatus: OrderStatus, timeToFinish?: number) {

        try {
            const formattedDate = dayjs().format('YYYY-MM-DD');
            const orderDocRef = doc(firestore, 'orders', restaurantId);


            const ordersWithReadyAt = timeToFinish ? updateOrderReadyAt(orderId, timeToFinish) : orders

            const maxOrderNumber = Math.max(...ordersWithReadyAt.map(x => x.orderNumber));

            console.log("ordersWithReadyAt", ordersWithReadyAt);


            const updatedOrders = ordersWithReadyAt.map((x, index) => {
                const orderNumber = x.orderNumber == 0 ? maxOrderNumber + 1 : x.orderNumber
                return x.id === orderId ? { ...x, orderStatus, orderNumber } : x
            })

            const orderDocSnapshot = await getDoc(orderDocRef);
            if (orderDocSnapshot.exists()) {

                const mappedOrders = updatedOrders.map(order => {

                    const mappedOrder = { ...order, createdAt: toTimestamp(order.createdAt) }
                    if (mappedOrder.readyAt === undefined) {
                        delete mappedOrder.readyAt
                        return mappedOrder
                    }
                    return { ...mappedOrder, readyAt: toTimestamp(mappedOrder.readyAt) }
                })

                await updateDoc(orderDocRef, { [formattedDate]: mappedOrders });
                await fetchOrders()

                const orderStatusToSend = updatedOrders.find(x => x.id === orderId)

                console.log("orderStatus", orderStatus, orderStatusToSend);


                set(ref(database, `orders/${restaurantId.replace(/\./g, '')}/${orderId}`), {
                    orderStatus,
                    orderNumber: orderStatusToSend?.orderNumber,
                    readyAt: orderStatusToSend?.readyAt?.unix() ?? 0,
                    createdAt: orderStatusToSend?.createdAt.unix()
                });


            } else {
                alert('Order not found. Please try again.');
            }
        } catch (error) {
            console.error('Error updating document: ', error);
        }
    }

    function getSwedishOrderStatus(orderStatus: OrderStatus) {
        switch (orderStatus) {
            case 'created':
                return 'Skapad';
            case 'accepted':
                return 'Accepterad';
            case 'finished':
                return 'Färdig';
            case 'delivered':
                return 'Levererad';
            case 'declined':
                return 'Avvisad';
            default:
                return orderStatus;
        }
    }

    function updateOrderReadyAt(orderId: string, timeToFinish: number) {
        return orders.map((order: OrderData) => {
            if (order.id === orderId) {
                return { ...order, readyAt: dayjs().add(timeToFinish, 'minutes') }
            }
            return order
        })
    }

    function incrementTimeToFinish(orderId: string) {
        setTimeToFinish(prev => ({
            ...prev,
            [orderId]: ((prev[orderId] ?? 15) + 5),
        }));
    };

    function decrementTimeToFinish(orderId: string) {
        setTimeToFinish(prev => ({
            ...prev,
            [orderId]: Math.max((prev[orderId] ?? 15) - 5, 0),
        }));
    };

    const buttonStyle = {
        backgroundColor: '#f9f9f9',
        color: '#333',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '5px 10px',
        cursor: 'pointer',
    };

    const activeButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#007bff',
        color: '#fff',
    };

    const acceptButtonStyle = {
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
    };

    const declineButtonStyle = {
        backgroundColor: '#dc3545',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
    };

    const finishButtonStyle = {
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
    };

    const decrementButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#f44336', // Red
        color: 'white',
        margin: '0 5px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: "35px"
    };

    const incrementButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#4CAF50', // Green
        color: 'white',
        margin: '0 5px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: "35px"
    };

    const inputStyle = {
        margin: '0 5px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: "35px"
    };

    function getFiltredOrderList() {
        if (orders === undefined || orders.length === 0) {
            return <p>Det har inte kommit in några ordrar idag</p>
        }

        const filtredOrders = orders
            .filter(order => (filter === 'active' && (order.orderStatus === 'finished' || order.orderStatus === 'accepted' || order.orderStatus === 'created')) || order.orderStatus === filter)
            .sort((a, b) => {
                if (a.orderStatus === 'created' && b.orderStatus !== 'created') {
                    return -1;
                } else if (a.orderStatus !== 'created' && b.orderStatus === 'created') {
                    return 1;
                } else if (a.orderStatus === 'created' && b.orderStatus === 'created') {
                    return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1;
                } else {
                    return dayjs(a.readyAt).isBefore(dayjs(b.readyAt)) ? -1 : 1;
                }
            });
        if (filtredOrders === undefined || filtredOrders.length === 0) {
            return <p>Det finns inga ordrar innom vald kategori</p>
        }

        return filtredOrders.map(order => {

            const deliveryTimeHoursAndMinuter = order.deliveryTime.split(":").map(x => Number(x))
            const deliveryTime = dayjs().set('hours', deliveryTimeHoursAndMinuter[0]).set('minutes', deliveryTimeHoursAndMinuter[1])
            const timeDifference = deliveryTime.diff(dayjs(), 'minutes');

            const timeToFinnishDefault = timeDifference < 15 ? 15 : Math.trunc(timeDifference / 5) * 5

            return <div key={order.id} style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '10px', backgroundColor: getOrderStatusBackgroundColor(order.orderStatus), display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <div>
                    <strong>Ordernummer:</strong> {order.orderNumber === 0 ? '' : order.orderNumber}<br />
                    <strong>Ordertyp:</strong> {order.orderType === 'eat-in' ? 'Äta här' : 'Ta med'}<br />
                    <strong>Pris:</strong> {order.totalPrice} Kr<br />
                    <strong>Orderstatus:</strong> {getSwedishOrderStatus(order.orderStatus)}<br />
                    <strong>Önskad till:</strong> <span style={{
                        backgroundColor: timeDifference > 25 ? 'red' : 'transparent',
                    }}>{order.deliveryTime}</span><br />
                    <strong>Skapad:</strong> {order.createdAt.format("HH:mm")}<br />
                    <strong>färdig till:</strong> {order.readyAt?.format("HH:mm")}<br />
                    <strong>Kommentarer:</strong> {order.comments}<br />
                </div>
                <div>
                    <strong>Kontaktinformation:</strong><br />
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li>{order.contactInfo.name}</li>
                        <li>{order.contactInfo.phoneNumber}</li>
                        <li>{order.contactInfo.email}</li>
                    </ul>
                    <strong>Beställning:</strong>
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                        {order.meals.map((meal, mealIndex) => (
                            <li style={{ marginBottom: '10px', backgroundColor: 'wheat' }} key={mealIndex}>
                                {meal.name} {meal.description} - Antal: {meal.quantity}
                            </li>
                        ))}
                    </ul>
                    {order.orderStatus === 'created' && (
                        <div style={{ marginTop: '10px' }}>
                            <div style={{ margin: "20px" }}>
                                <button
                                    onClick={() => decrementTimeToFinish(order.id)}
                                    style={decrementButtonStyle}
                                >
                                    -5
                                </button>
                                <input
                                    type="number"
                                    placeholder="Time to finish"
                                    value={timeToFinish[order.id] ?? timeToFinnishDefault}
                                    onChange={(e) => {
                                        const newTime = Math.max(Number(e.target.value), 0);
                                        setTimeToFinish({ ...timeToFinish, [order.id]: newTime });
                                    }}
                                    style={inputStyle}
                                />
                                <button
                                    onClick={() => incrementTimeToFinish(order.id)}
                                    style={incrementButtonStyle}
                                >
                                    +5
                                </button>
                            </div>
                            <button
                                style={{ ...acceptButtonStyle, marginRight: "10px" }}
                                onClick={() => updateStatus(order.id, 'accepted', timeToFinish[order.id] ?? timeToFinnishDefault)}
                            >
                                Acceptera
                            </button>
                            <button style={declineButtonStyle} onClick={() => updateStatus(order.id, 'declined', 0)}>Avvisa</button>
                        </div>
                    )}
                    {order.orderStatus === 'accepted' && (
                        <div style={{ marginTop: '10px' }}>
                            <button style={finishButtonStyle} onClick={() => updateStatus(order.id, 'finished')}>Färdig</button>
                        </div>
                    )}
                    {order.orderStatus === 'finished' && (
                        <div style={{ marginTop: '10px' }}>
                            <button style={finishButtonStyle} onClick={() => updateStatus(order.id, 'delivered')}>Levererad </button>
                        </div>
                    )}
                </div>
            </div>
        })
    }


    return (
        <>
            <audio ref={audioRef} src="/static/notification.mp3" />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1>Orders</h1>
                <button style={{ ...buttonStyle, marginRight: "20px" }} onClick={handleLogout}>Logga ut</button>
            </div>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <button style={filter === 'active' ? activeButtonStyle : buttonStyle} onClick={() => setFilter('active')}>
                    Akiva
                </button>
                <button style={filter === 'created' ? activeButtonStyle : buttonStyle} onClick={() => setFilter('created')}>
                    Skapade
                </button>
                <button style={filter === 'accepted' ? activeButtonStyle : buttonStyle} onClick={() => setFilter('accepted')}>
                    Accepterade
                </button>
                <button style={filter === 'finished' ? activeButtonStyle : buttonStyle} onClick={() => setFilter('finished')}>
                    Färdiga
                </button>
                <button style={filter === 'delivered' ? activeButtonStyle : buttonStyle} onClick={() => setFilter('delivered')}>
                    Levererade
                </button>
                <button style={filter === 'declined' ? activeButtonStyle : buttonStyle} onClick={() => setFilter('declined')}>
                    Avvisade
                </button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {getFiltredOrderList()}
            </div>
        </>
    );
};

export default ShowOrders;
