import React, { useState } from 'react';
import { useMealContext } from './MealContext';
import { ResturantContactInfo } from './types';
import { firestore } from './firebase';
import { doc, updateDoc } from '@firebase/firestore';
import { toTimestamp } from './util';

const ContactEditor: React.FC = () => {
    const { restaurant, fetchRestaurant } = useMealContext();
    const [contactInfo, setContactInfo] = useState<ResturantContactInfo>(restaurant!.contact);
    const [editInfo, setEditInfo] = useState<ResturantContactInfo>(contactInfo);
    const [isEdited, setIsEdited] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInfo({
            ...editInfo,
            [e.target.name]: e.target.value,
        });
        setIsEdited(true); // Set isEdited to true when the form is edited
    };

    const handleConfirm = async () => {
        try {
            const restaurantRef = doc(firestore, "restaurants", restaurant!.id)
            await updateDoc(restaurantRef, {
                contact: {
                    ...editInfo,
                    openingHours: editInfo.openingHours.map(openingHours => {
                        return {
                            open: toTimestamp(openingHours.open),
                            close: toTimestamp(openingHours.close),
                            closed: openingHours.closed
                        }
                    })
                },
            });
            console.log('Contact information updated successfully!');
        } catch (error) {
            console.error('Error updating contact information:', error);
            throw error;
        }
        setContactInfo(editInfo);
        setIsEdited(false);
        fetchRestaurant()
    }

    const handleCancel = () => {
        setEditInfo(contactInfo); // Reset editInfo to the last saved state
        setIsEdited(false); // Reset isEdited to false when cancelled
    };

    const formStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '400px',
        borderRadius: '8px',
    };

    const inputStyle: React.CSSProperties = {
        margin: '10px 0',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    };

    const buttonStyle: React.CSSProperties = {
        padding: '10px',
        margin: '5px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: 'none',
        color: 'white',
    };

    return (
        <div>
            {!isEdited ? (
                <div>
                    <h1>Kontakt</h1>
                    <p>Adress: {contactInfo.address}</p>
                    <p>Telefonnummer: {contactInfo.phone}</p>
                    <p>Email: {contactInfo.email}</p>
                    <button onClick={() => setIsEdited(true)} style={{ ...buttonStyle, backgroundColor: '#4CAF50' }}>Ändra</button>
                </div>
            ) : (
                <div>
                    <h1>Ändra kontaktinformation</h1>
                    <form style={formStyle} onSubmit={(e) => e.preventDefault()}>
                        <input
                            type="text"
                            name="address"
                            placeholder="Adress"
                            value={editInfo.address}
                            onChange={handleChange}
                            style={inputStyle}
                        />
                        <input
                            type="text"
                            name="phone"
                            placeholder="Telefonnummer"
                            value={editInfo.phone}
                            onChange={handleChange}
                            style={inputStyle}
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={editInfo.email}
                            onChange={handleChange}
                            style={inputStyle}
                        />
                    </form>
                    <button type="button" onClick={handleConfirm} style={{ ...buttonStyle, backgroundColor: '#4CAF50' }}>Spara</button>
                    <button type="button" onClick={handleCancel} style={{ ...buttonStyle, backgroundColor: '#f44336' }}>Avbryt</button>
                </div>
            )}
        </div>
    );
};

export default ContactEditor;
